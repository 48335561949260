.App {
  text-align: center;
  background: #282c34;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.main-text {
  font-size: 21px;
  font-family: Arial, Helvetica, sans-serif;
  text-align: justify;
  max-width: 1000px;
  margin: auto;
}

.header {
  display: flex;
  align-items: center;
  justify-content: right;
  font-size: calc(10px + 1vmin);
  color: black;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.navbar {
  list-style-type: none; /* Remove default list bullets */
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.navbar li {
  float: left; /* Arrange items horizontally */
}

.navbar li a {
  display: block;
  color: white; /* Ensure links are also white for visibility */
  text-align: center;
  padding: 14px 16px; /* Spacing around links */
  text-decoration: none; /* Remove underline from links */
}

/* Change link color on hover */
.navbar li a:hover {
  background-color: #ddd;
  color: black;
}